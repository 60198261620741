import { lazy } from "react";

import { ROLES } from "../Utils/roleAndPermUtils";
import i18n from "../i18n";
import * as myRouters from "../Utils/routerConstants";

const BackendLayout = lazy(() => import("../Backend/Layout/BackendLayout"));
const DashboardPage = lazy(() =>
  import("../Backend/Pages/Dashboard/DashboardPage")
);
const ProfilePage = lazy(() => import("../Backend/Pages/Profile/ProfilePage"));
const ChangePasswordPage = lazy(() =>
  import("../Backend/Pages/Account/ChangePassword/ChangePasswordPage")
);
const HistoryLogPage = lazy(() =>
  import("../Backend/Pages/HistoryLog/HistoryLogPage")
);

const NotAuthorizationPage = lazy(() =>
  import("../Backend/Pages/NotAuthorization/NotAuthorizationPage")
);
const WelcomePage = lazy(() =>
  import("../Backend/Pages/NotAuthorization/WelcomePage")
);

// COURSE
const CourseDashboardPage = lazy(() =>
  import("../Backend/Pages/Course/CourseDashboardPage")
);
const CourseCategoryListPage = lazy(() =>
  import("../Backend/Pages/Course/CourseCategory/CourseCategoryListPage")
);
const CourseIndexListPage = lazy(() =>
  import("../Backend/Pages/Course/CourseIndex/CourseIndexListPage")
);
const CourseCommentListPage = lazy(() =>
  import("../Backend/Pages/Course/CourseComment/CourseCommentListPage")
);
const CourseRegisterWebinarPage = lazy(() =>
  import(
    "../Backend/Pages/Course/CourseRegisterWebinar/CourseRegisterWebinarPage"
  )
);
const CourseQuestionAndAnswerPage = lazy(() =>
  import(
    "../Backend/Pages/Course/CourseQuestionAndAnswer/CourseQuestionAndAnswerPage"
  )
);
const SchoolITInVietNamPage = lazy(() =>
  import("../Backend/Pages/Course/SchoolITInVietNam/SchoolITInVietNamPage")
);
const CompanyITInVietNamPage = lazy(() =>
  import("../Backend/Pages/Course/CompanyITInVietNam/CompanyITInVietNamPage")
);
const MarketITInVietNamPage = lazy(() =>
  import("../Backend/Pages/Course/MarketITInVietNam/MarketITInVietNamPage")
);
const MarketITInAbroadPage = lazy(() =>
  import("../Backend/Pages/Course/MarketITInAbroad/MarketITInAbroadPage")
);
// student with course
const StudentIndexListPage = lazy(() =>
  import("../Backend/Pages/Course/Student/StudentIndexListPage")
);
const StudentSupportCourseIndexListPage = lazy(() =>
  import("../Backend/Pages/Course/Student/StudentSupportCourseIndexListPage")
);
const StudentSSSupportCourseIndexListPage = lazy(() =>
  import(
    "../Backend/Pages/Course/Student/StudentSecondarySchool/StudentSSSupportCourseIndexList"
  )
);
const StudentCourseLicensePage = lazy(() =>
  import(
    "../Backend/Pages/Course/Student/StudentCourseLicense/StudentCourseLicensePage"
  )
);
const MyNewHighSchoolStudentListPage = lazy(() =>
  import("../Backend/Pages/Student/StudentHighSchool/MyNewStudentListPage")
);
const MyOldHighSchoolStudentListPage = lazy(() =>
  import("../Backend/Pages/Student/StudentHighSchool/MyOldStudentListPage")
);
const NewCheckInputStudentListPage = lazy(() =>
  import(
    "../Backend/Pages/Student/CheckInputCourse/NewCheckInputStudentListPage"
  )
);

// SOFTWARE CAREER ======================

const LeaderSupportHighSchoolPage = lazy(() =>
  import(
    "../Backend/Pages/Course/Student/StudentHighSchool/LeaderSupportHighSchoolPage"
  )
);

// TEST COURSE
const TestCoursePage = lazy(() =>
  import("../Backend/Pages/TestCourse/TestCoursePage")
);
const TestCourseQuestionPage = lazy(() =>
  import("../Backend/Pages/TestCourseQuestion/TestCourseQuestionPage")
);

// PRACTICE COURSE
const PracticeCoursePage = lazy(() =>
  import("../Backend/Pages/PracticeCourse/PracticeCoursePage")
);
const PracticeCourseQuestionPage = lazy(() =>
  import("../Backend/Pages/PracticeCourseQuestion/PracticeCourseQuestionPage")
);
const GraduationExamPracticeCourseListPage = lazy(() =>
  import(
    "../Backend/Pages/Student/GraduationExamPracticeCourse/GraduationExamPracticeCourseListPage"
  )
);

// EXAM COURSE
const ExamCoursePage = lazy(() =>
  import("../Backend/Pages/ExamCourse/ExamCoursePage")
);
const ExamCourseQuestionPage = lazy(() =>
  import("../Backend/Pages/ExamCourseQuestion/ExamCourseQuestionPage")
);
const StudentReadyExamGraduationListPage = lazy(() =>
  import(
    "../Backend/Pages/Student/GraduationExamCourse/StudentReadyExamGraduationListPage"
  )
);

// LESSON COURSE
const LessonCoursePage = lazy(() =>
  import("../Backend/Pages/LessonCourse/LessonCoursePage")
);
const LessonCourseDetailPage = lazy(() =>
  import("../Backend/Pages/LessonCourseDetail/LessonCourseDetailPage")
);

// REFLEX MEMORY COURSE
const ReflexMemoryCoursePage = lazy(() =>
  import("../Backend/Pages/ReflexMemoryCourse/ReflexMemoryCoursePage")
);
const ReflexMemoryCourseDetailPage = lazy(() =>
  import(
    "../Backend/Pages/ReflexMemoryCourseDetail/ReflexMemoryCourseDetailPage"
  )
);

// REFLEX RESPONSE COURSE
const ReflexResponseCoursePage = lazy(() =>
  import("../Backend/Pages/ReflexResponseCourse/ReflexResponseCoursePage")
);
const ReflexResponseCourseDetailPage = lazy(() =>
  import(
    "../Backend/Pages/ReflexResponseCourseDetail/ReflexResponseCourseDetailPage"
  )
);

// RESULT PRACTICE COURSE
const CheckInputListPage = lazy(() =>
  import(
    "../Backend/Pages/Student/ResultPracticeCourse/CheckInput/CheckInputListPage"
  )
);
const PracticeExerciseListPage = lazy(() =>
  import(
    "../Backend/Pages/Student/ResultPracticeCourse/PracticeExercise/PracticeExerciseListPage"
  )
);
const ExamFinishListPage = lazy(() =>
  import(
    "../Backend/Pages/Student/ResultPracticeCourse/ExamFinish/ExamFinishListPage"
  )
);
const PracticeExamListPage = lazy(() =>
  import(
    "../Backend/Pages/Student/ResultPracticeCourse/PracticeExam/PracticeExamListPage"
  )
);
const PracticeReflexMemoryListPage = lazy(() =>
  import(
    "../Backend/Pages/Student/ResultPracticeCourse/PracticeReflexMemory/PracticeReflexMemoryListPage"
  )
);
const PracticeReflexResponseListPage = lazy(() =>
  import(
    "../Backend/Pages/Student/ResultPracticeCourse/PracticeReflexResponse/PracticeReflexResponseListPage"
  )
);

//
const BillListPage = lazy(() => import("../Backend/Pages/Bill/BillListPage"));

//
const StudyingTipsPage = lazy(() =>
  import("../Backend/Pages/StudyingTips/StudyingTipsPage")
);

//
const StudentRequestRenewCoursePage = lazy(() =>
  import(
    "../Backend/Pages/Student/StudentRequestRenewCourse/StudentRequestRenewCoursePage"
  )
);
const StudentRequestRenewReviewedCoursePage = lazy(() =>
  import(
    "../Backend/Pages/Student/StudentRequestRenewCourse/StudentRequestRenewReviewedCoursePage"
  )
);

//
const routers = [
  {
    path: myRouters.DASHBOARD_URL,
    name: i18n.t("Dashboard"),
    component: DashboardPage,
    layout: BackendLayout,
    exact: true,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.PROFILE_PAGE_URL,
    name: i18n.t("Profile"),
    component: ProfilePage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.HISTORY_LOG_PAGE_URL,
    name: i18n.t("History log"),
    component: HistoryLogPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MANAGER],
  },
  {
    path: myRouters.CHANGE_PASSWORD_PAGE_URL,
    name: "Thay đổi mật khẩu",
    component: ChangePasswordPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },

  // COURSE
  {
    path: myRouters.COURSE_DASHBOARD_PAGE_URL,
    name: "Course - Bảng điều khiển",
    component: CourseDashboardPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.COURSE_CATEGORY_LIST_PAGE_URL,
    name: "DANH MỤC KHÓA HỌC",
    component: CourseCategoryListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path:
      myRouters.COURSE_INDEX_LIST_PAGE_URL +
      "/:course_category_level2_id/:course_category_level2_name",
    name: "Mục lục course",
    component: CourseIndexListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path:
      myRouters.COURSE_COMMENT_LIST_PAGE_URL +
      "/:course_category_level2_id/:course_category_level2_name",
    name: "Comment course",
    component: CourseCommentListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.COURSE_CONTACT_WEBINAR_LIST_PAGE_URL,
    name: "Webinar liên hệ",
    component: CourseRegisterWebinarPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.COURSE_QUESTION_LIST_PAGE_URL,
    name: "Học viên hỏi đáp",
    component: CourseQuestionAndAnswerPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.SCHOOL_IT_IN_VIETNAM_LIST_PAGE_URL,
    name: "Trường IT VietNam",
    component: SchoolITInVietNamPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.COMPANY_IT_IN_VIETNAM_LIST_PAGE_URL,
    name: "Company IT VietNam",
    component: CompanyITInVietNamPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.MARKET_IT_IN_VIETNAM_LIST_PAGE_URL,
    name: "Market IT VietNam",
    component: MarketITInVietNamPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  {
    path: myRouters.MARKET_IT_IN_ABROAD_LIST_PAGE_URL,
    name: "Market IT tại nước ngoài",
    component: MarketITInAbroadPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN],
  },
  // STUDENT WITH COURSE
  {
    path: myRouters.COURSE_STUDENT_INDEX_LIST_PAGE_URL,
    name: "Danh sách học viên",
    component: StudentIndexListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path: myRouters.COURSE_SUPPORT_STUDENT_HS_INDEX_LIST_PAGE_URL,
    name: "Hỗ trợ bài giảng HS",
    component: StudentSupportCourseIndexListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path: myRouters.COURSE_SUPPORT_STUDENT_HS_NEW_INDEX_LIST_PAGE_URL,
    name: "Học viên HS MỚI",
    component: MyNewHighSchoolStudentListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path: myRouters.COURSE_SUPPORT_STUDENT_HS_OLD_INDEX_LIST_PAGE_URL,
    name: "Học viên HS CŨ",
    component: MyOldHighSchoolStudentListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path: myRouters.COURSE_SUPPORT_LEADER_SUPPORT_HS_INDEX_LIST_PAGE_URL,
    name: "Leader hỗ trợ HS",
    component: LeaderSupportHighSchoolPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },

  // ==================================
  {
    path: myRouters.COURSE_SUPPORT_STUDENT_SS_INDEX_LIST_PAGE_URL,
    name: "Hỗ trợ bài giảng SS",
    component: StudentSSSupportCourseIndexListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path:
      myRouters.COURSE_STUDENT_LICENSE_LIST_PAGE_URL +
      "/:student_me_id/:student_me_info",
    name: "Cập nhật chứng nhận, chứng chỉ",
    component: StudentCourseLicensePage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  // TEST COURSE
  {
    path:
      myRouters.TEST_COURSE_LIST_PAGE_URL +
      "/:course_index_level2_id/:course_index_level2_name/:course_lang_code/:course_category_level2_id/:course_category_level2_name",
    name: "BÀI TẬP VỀ NHÀ",
    component: TestCoursePage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path:
      myRouters.TEST_COURSE_QUESTION_LIST_PAGE_URL +
      "/:test_course_id/:test_course_name/:course_index_level2_id/:course_index_level2_name/:course_lang_code/:course_category_level2_id/:course_category_level2_name",
    name: "CÂU HỎI ĐỂ BÀI TẬP",
    component: TestCourseQuestionPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },

  // PRACTICE COURSE
  {
    path:
      myRouters.PRACTICE_COURSE_LIST_PAGE_URL +
      "/:course_category_level2_id/:course_category_level2_name/:course_lang_code",
    name: "LUYỆN THI",
    component: PracticeCoursePage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path:
      myRouters.PRACTICE_COURSE_QUESTION_LIST_PAGE_URL +
      "/:practice_course_id/:practice_course_name/:course_category_level2_id/:course_category_level2_name/:course_lang_code",
    name: "CÂU HỎI BÀI TẬP LUYỆN THI",
    component: PracticeCourseQuestionPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path: myRouters.COURSE_PRACTICE_EXERCISE_STUDENT_LIST_PAGE_URL,
    name: "HỌC VIÊN LUYỆN BÀI TẬP",
    component: MyNewHighSchoolStudentListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },

  // EXAM COURSE
  {
    path:
      myRouters.EXAM_COURSE_LIST_PAGE_URL +
      "/:course_category_level2_id/:course_category_level2_name/:course_lang_code",
    name: "BÀI THI KIỂM TRA",
    component: ExamCoursePage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path:
      myRouters.EXAM_COURSE_QUESTION_LIST_PAGE_URL +
      "/:exam_course_id/:exam_course_name/:course_category_level2_id/:course_category_level2_name/:course_lang_code",
    name: "CÂU HỎI ĐỂ THI",
    component: ExamCourseQuestionPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path: myRouters.COURSE_EXAM_PRACTICE_GRADUATION_STUDENT_LIST_PAGE_URL,
    name: "HỌC VIÊN LUYỆN TẬP THI CUỐI KHÓA",
    component: MyNewHighSchoolStudentListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path: myRouters.COURSE_EXAM_GRADUATION_STUDENT_READY_LIST_PAGE_URL,
    name: "HỌC VIÊN SẴN SÀNG THI CUỐI KHÓA",
    component: StudentReadyExamGraduationListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  // LESSON COURSE
  {
    path:
      myRouters.LESSON_COURSE_LIST_PAGE_URL +
      "/:course_index_level2_id/:course_index_level2_name/:course_lang_code/:course_category_level2_id/:course_category_level2_name",
    name: "BÀI HỌC",
    component: LessonCoursePage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path:
      myRouters.LESSON_COURSE_CONTENT_LIST_PAGE_URL +
      "/:lesson_course_id/:lesson_course_name/:course_index_level2_id/:course_index_level2_name/:course_lang_code/:course_category_level2_id/:course_category_level2_name",
    name: "NỘI DUNG BÀI HỌC",
    component: LessonCourseDetailPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  // REFLEX MEMORY COURSE
  {
    path:
      myRouters.REFLEX_MEMORY_COURSE_LIST_PAGE_URL +
      "/:course_category_level2_id/:course_category_level2_name/:course_lang_code",
    name: "GHI NHỚ PHẢN XẠ",
    component: ReflexMemoryCoursePage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path:
      myRouters.REFLEX_MEMORY_COURSE_CONTENT_LIST_PAGE_URL +
      "/:reflex_memory_course_id/:reflex_memory_course_name/:course_category_level2_id/:course_category_level2_name/:course_lang_code",
    name: "NỘI DUNG GHI NHỚ PHẢN XẠ",
    component: ReflexMemoryCourseDetailPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  //
  {
    path: myRouters.COURSE_SUPPORT_STUDENT_CHECK_INPUT_COURSE_LIST_PAGE_URL,
    name: "HỌC VIÊN TIỀM NĂNG TEST ĐẦU VÀO",
    component: NewCheckInputStudentListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path: myRouters.COURSE_PRACTICE_REFLEX_MEMORY_STUDENT_LIST_PAGE_URL,
    name: "HỌC VIÊN LUYỆN GHI NHỚ PHẢN XẠ",
    component: MyNewHighSchoolStudentListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },

  // REFLEX RESPONSE COURSE
  {
    path:
      myRouters.REFLEX_RESPONSE_COURSE_LIST_PAGE_URL +
      "/:course_category_level2_id/:course_category_level2_name/:course_lang_code",
    name: "ĐỐI ĐÁP PHẢN XẠ",
    component: ReflexResponseCoursePage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path:
      myRouters.REFLEX_RESPONSE_COURSE_CONTENT_LIST_PAGE_URL +
      "/:reflex_response_course_id/:reflex_response_course_name/:course_category_level2_id/:course_category_level2_name/:course_lang_code",
    name: "NỘI DUNG ĐỐI ĐÁP PHẢN XẠ",
    component: ReflexResponseCourseDetailPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path: myRouters.COURSE_PRACTICE_REFLEX_RESPONSE_STUDENT_LIST_PAGE_URL,
    name: "HỌC VIÊN LUYỆN ĐỐI ĐÁP PHẢN XẠ",
    component: MyNewHighSchoolStudentListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  //
  // RESULT PRACTICE COURSE
  {
    path:
      myRouters.COURSE_STUDENT_RESULT_CHECK_INPUT_PAGE_URL +
      "/:student_me_id/:student_me_info/:student_me_email",
    name: "KẾT QUẢ TEST ĐẦU VÀO",
    component: CheckInputListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path:
      myRouters.COURSE_STUDENT_RESULT_PRACTICE_EXERCISE_PAGE_URL +
      "/:student_me_id/:student_me_info/:student_me_email",
    name: "KẾT QUẢ LUYỆN BÀI TẬP",
    component: PracticeExerciseListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path:
      myRouters.COURSE_STUDENT_RESULT_PRACTICE_EXAM_PAGE_URL +
      "/:student_me_id/:student_me_info/:student_me_email",
    name: "KẾT QUẢ LUYỆN THI",
    component: PracticeExamListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path:
      myRouters.COURSE_STUDENT_RESULT_EXAM_FINISH_PAGE_URL +
      "/:student_me_id/:student_me_info/:student_me_email",
    name: "KẾT QUẢ THI CUỐI KHÓA",
    component: ExamFinishListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path:
      myRouters.COURSE_STUDENT_RESULT_PRACTICE_REFLEX_MEMORY_PAGE_URL +
      "/:student_me_id/:student_me_info/:student_me_email",
    name: "KẾT QUẢ LUYỆN GHI NHỚ PHẢN XẠ",
    component: PracticeReflexMemoryListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path:
      myRouters.COURSE_STUDENT_RESULT_PRACTICE_REFLEX_RESPONSE_PAGE_URL +
      "/:student_me_id/:student_me_info/:student_me_email",
    name: "KẾT QUẢ LUYỆN ĐỐI ĐÁP PHẢN XẠ",
    component: PracticeReflexResponseListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  //
  {
    path: myRouters.COURSE_PRACTICE_BONUS_STUDENT_LIST_PAGE_URL,
    name: "TỔNG KẾT ĐIỂM THƯỞNG",
    component: MyNewHighSchoolStudentListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  // ===================
  {
    path: myRouters.BILL_LIST_PAGE_URL,
    name: "HÓA ĐƠN",
    component: BillListPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },

  // ===================
  {
    path: myRouters.STUDYING_TIPS_LIST_PAGE_URL,
    name: "MẸO HỌC TẬP HIỆU QUẢ",
    component: StudyingTipsPage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },

  // ===================
  {
    path: myRouters.COURSE_STUDENT_REQUEST_RENEW_PAGE_URL,
    name: "YÊU CẦU GIA HẠN KHÓA HỌC",
    component: StudentRequestRenewCoursePage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },
  {
    path: myRouters.COURSE_STUDENT_REQUEST_RENEW_REVIEWED_PAGE_URL,
    name: "YÊU CẦU ĐÃ GIA HẠN",
    component: StudentRequestRenewReviewedCoursePage,
    layout: BackendLayout,
    allows: [ROLES.ADMIN, ROLES.MEMBER],
  },

  // =====================================================
  // Authentication
  {
    path: myRouters.NO_PERMISSION_URL,
    name: "Bạn không được phép ở trang này!",
    component: NotAuthorizationPage,
    layout: BackendLayout,
  },
  {
    path: myRouters.WELCOME_URL,
    name: "CHÚC MỘT NGÀY TỐT LÀNH!",
    component: WelcomePage,
    layout: BackendLayout,
  },
];

export default routers;
